<template>
  <div v-if="localDesign">
    <div :id="`konva-parent-${_uid}`" class="rounded" style="overflow:hidden">
    <table style="margin-left: auto; margin-right: auto"> 
      <tr>
      <td v-if="!preview" valign="bottom" align="right" style="width:200px;">
        <v-list dense>
            <v-list-item
              v-for="p in getSortedPelottaListSide('left')"
              :key="p.name"
              @click="
                () => {
                  selectPelotta(p.name)
                }
              "
            >
              <v-list-item>
                <v-chip label big class="mt-0" :color="p.locked ? 'red' : 'transparent'">
                  {{ getPelottaDescription(p) }}
                </v-chip>
              </v-list-item>
            </v-list-item>
        </v-list>
      </td>
      <td>
      <v-stage
        ref="stage"
        :config="{ width, height }"
        @mousedown="handleStageMouseDown"
        @touchstart="handleStageMouseDown"
        @contextmenu="contextmenuDisable"
        @mousemove="handleStageMouseMove"
        @mouseup="handleStageMouseUp"
        @dblclick="handleStageDblClick"
      >
        <v-layer ref="bgLayer">
          <v-image
            v-if="feetImage"
            :config="{
              width,
              height,
              image: feetImage,
              opacity: design.imageOpacity,
              scaleX: -1,
              x: width
            }"
          />
        </v-layer>
        <v-layer ref="annotationLayer">
          <v-text :config="{
            text: $t('left'),
            fontSize: 70,
            x: 20,
            y: 20,
            shadowBlur: 5,
            shadowOffsetX: 3,
            shadowOffsetY: 3,
            shadowColor: '#FFFFFF',
            shadowOpacity: 1.0,
          }"/>
          <v-text :config="{
            text: $t('right'),
            fontSize: 70,
            x: width-200,
            y: 20,
            shadowBlur: 5,
            shadowOffsetX: 3,
            shadowOffsetY: 3,
            shadowColor: '#FFFFFF',
            shadowOpacity: 1.0,
          }"/>
        </v-layer>
        <v-layer ref="pelottaLayer">
          <v-image
            v-for="(item, i) in pelottaList"
            :key="i"
            :config="item"
          />
        </v-layer>
      </v-stage>
      </td> 
      <td v-if="!preview" valign="bottom"  style="width:200px;">
        <v-list dense>
            <v-list-item
              v-for="p in getSortedPelottaListSide('right')"
              :key="p.name"
              @click="
                () => {
                  selectPelotta(p.name)
                }
              "
            >
              <v-list-item>
                <v-chip label big class="mt-0" :color="p.locked ? 'red' : 'transparent'">
                  {{ getPelottaDescription(p) }}
                </v-chip>
              </v-list-item>
            </v-list-item>
        </v-list>
      </td>
      
      </tr> </table>
      
      <v-toolbar v-if="!readonly" dark color="primary" height="40px">
        <template v-if="selectedShape">
          {{ selectedShape.pelotta.name }}
          <span class="ml-2" style="opacity: 0.5">
            {{ $t("size-n", { n: selectedShape.pelotta.size }) }}
          </span>
        </template>
        <template v-else>
          <span class="mr-2" style="opacity: 0.5">
            {{ $t("choose-an-item") }}
          </span>
        </template>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            dark
            color="error"
            text
            @click="$emit('quickSave')"
          >
          {{ $t("save") }}
          </v-btn>
          <v-select
            hide-details
            style="max-width: 200px"
            v-model="colorScheme"
            solo-inverted
            flat
            :items="['color', 'bw', 'original'].map(value => ({ text: $t(value), value }))"
          />
        </v-toolbar-items>
      </v-toolbar>
    </div>
  </div>
</template>
<script>

import DesignMixin from "@/mixins/DesignMixin";
import { applyThreshold, getHeatMap } from "@/utils/imageUtils";

export default {
  name: "KonvaDesigner",
  mixins: [DesignMixin],
  props: {
    preview: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 1600
    },
    widthMM: {
      type: Number,
      default: 452.0
    },
    height: {
      type: Number,
      default: 1200
    },
    heightMM: {
      type: Number,
      default: 339.0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    threshold: {
      type: Number
    },
    contrast: {
      type: Number
    },
    holdRightRotationMode: {
      type: Boolean,
      default: true
    },
    doubleClickDragMode: {
      type: Boolean,
      default: true
    },
    maxSelectDistance: {
      type: Number,
      default:200
    }
  },
  data() {
    return {
      colorScheme: "color",
      feetImage: null,
      selectedShapeName: ""
    };
  },
  mounted() {
    this.lastReleaseTime = Date.now();
    this.downloadFeetImage();
    this.$nextTick(() => this.fitStageIntoParentContainer());
    window.setTimeout(() => this.fitStageIntoParentContainer(), 100);
    this.history = [];
    this.redoHistory = [];
    this.rotationMode = false;
    this.draggingMode = false;
    this.rotationInsideDraggingMode = false;
    this.draggingPelottaOffsetX = 0;
    this.draggingPelottaOffsetY = 0;
    this.checkAndPlaceBasePlates();
    
  },
  created() {
    window.addEventListener("resize", this.fitStageIntoParentContainer);
    window.addEventListener('keydown', this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener("resize", this.fitStageIntoParentContainer);
    window.removeEventListener('keydown', this.handleKeyPress);
  },
  computed: {
    pelottaList() {
      this.updateComputedData();
      return this.localDesign.pelottas.map((p, i) => {
        const image = new Image();
        
        image.src = `data:image/svg+xml;base64,${btoa(p.data)}`;
        
        var UID;
        
        if (p.UID) {
          UID = p.UID;
        }
        else {
          UID = `p${i}`;
        }
        
        var shadowColor;

        if (p.locked)
        {
          if (p.side == "right")
          {
            shadowColor = "#000000";
          }
          else
          {
            shadowColor = "#000000";
          }
        }
        else
        {
          if (p.side == "right")
          {
            shadowColor = "#FF0000";
          }
          else
          {
            shadowColor = "#00FF00";
          }
        }
        
        if (UID == this.selectedShapeName)
        {
          shadowColor = "#FFFFFF";
        }
        
        var widthInPixels = 100;
        var heightInPixels = 100;
        
        if (this.widthMM && this.heightMM && this.width && this.height && p.widthMM && p.heightMM) {
          //console.log(this.widthMM , this.heightMM , this.width , this.height);
          p.xPixelPerMM = this.width / this.widthMM;
          p.yPixelPerMM = this.height / this.heightMM;
          
          //console.log( p.widthMM,  p.heightMM, p.xPixelPerMM, p.yPixelPerMM);
          
          widthInPixels = p.widthMM * p.xPixelPerMM;
          heightInPixels = p.heightMM * p.yPixelPerMM;
        }
        else {
          console.log("Error parameter missing", this.widthMM, this.heightMM, this.width, this.height, p.widthMM, p.heightMM);
          
          p.xPixelPerMM = 1.0;
          p.yPixelPerMM = 1.0;
        }
        
        //console.log(widthInPixels, heightInPixels);
        
        return {
          ...p,
          name: UID,
          image,
          //draggable: !this.readonly && !p.locked,
          draggable: false,
          shadowBlur: 5,
          shadowOffsetX: 2,
          shadowOffsetY: 2,
          shadowColor: shadowColor,
          shadowOpacity: 1.0,
          width: widthInPixels,
          height: heightInPixels
        };
      });
    },
    sortedPelottaList()
    {
      var pl = this.pelottaList.map((x) => x);
      pl.sort(this.pelottaOrdering);
      return pl;
    },
    selectedShape() {
      if (!this.selectedShapeName) return null;
      const p = this.localDesign.pelottas[this.currentPelottaIndex];
      return {
        ...p,
        pelotta: this.pelottas.find(
          ({ PelottaID }) => PelottaID === p.PelottaID
        )
      };
    },
    currentPelottaIndex() {
      return this.pelottaList.findIndex(
        ({ name }) => name === this.selectedShapeName
      );
    },
    currentPelottaIndexInSortedList() {
      return this.sortedPelottaList.findIndex(
        ({ name }) => name === this.selectedShapeName
      );
    },
    currentGroup() {
      return this.pelottaGroups.find(
        group => group.name === this.selectedShape.pelotta.name
      );
    },
    currentPelottaIndexInGroup() {
      return this.currentGroup.Items.findIndex(
        ({ PelottaID }) => PelottaID === this.selectedShape.PelottaID
      );
    }
  },
  methods: {
    contextmenuDisable(e) {
      e.evt.preventDefault();
    },
    pushToHistory() {
      //console.log("this.history", this.history.length);
      
      if (this.history.length > 100)
      {
        this.history.unshift();
      }
      
      this.history.push(JSON.stringify(this.localDesign.pelottas));
      this.redoHistory = [];
      
      //this.localDesign.pelottas = JSON.parse(JSON.stringify(this.localDesign.pelottas));
      //console.log(JSON.stringify(this.localDesign.pelottas));
    },
    pullFromHistory() {
      //console.log("this.history", this.history.length);
      if (this.history.length > 0)
      {
        this.redoHistory.push(JSON.stringify(this.localDesign.pelottas));
        this.localDesign.pelottas = JSON.parse(this.history.pop());
        this.update();
      }
    },
    redoFromHistory() {
      if (this.redoHistory.length > 0)
      {
        this.history.push(JSON.stringify(this.localDesign.pelottas));
        this.localDesign.pelottas = JSON.parse(this.redoHistory.pop());
        this.update();
      }
    },
    pelottaOrdering(a, b) {
      var aS = a.PelottaID + a.side + a.UID;
      var bS = b.PelottaID + b.side + b.UID;
      
      if (aS > bS)
      {
        return -1;
      }
      else if (aS < bS)
      {
        return 1;
      }
      else
      {
        return 0;
      }
    },
    getPelottaDescription(pelotta) {
      var text;
      if (pelotta.isBase)
      {
        text = this.$t(pelotta.rootName + "_alap") + " " + pelotta.sizeCode;
      }
      else
      {
        text = this.$t(pelotta.rootName) + " " + pelotta.sizeCode;
      }
      
      return text;
    },
    async downloadFeetImage() {
      if (!this.design) return;
      const imageData = await this.$store.dispatch("download", {
        PatientID: this.design.PatientID,
        FileName: this.design.imageUrl
      });
      
      var that = this;
      
      if (imageData.data) {
          var imgtemp = new Image();
          
          imgtemp.onload = function() {
          var canvas = document.createElement('canvas');
          canvas.width = imgtemp.width;
          canvas.height = imgtemp.height;
          
          const heatMap = getHeatMap(that.colorScheme);
          const ctx = canvas.getContext("2d");
          
          ctx.save();
          ctx.scale(-1, 1);
          ctx.drawImage(imgtemp, 0, 0, -imgtemp.width, imgtemp.height);
          ctx.restore();
          
          const imageData = ctx.getImageData(
            0,
            0,
            canvas.width,
            canvas.height
          );
          
          applyThreshold(
            imageData.data,
            that.threshold,
            that.contrast,
            heatMap
          );
          
          ctx.putImageData(imageData, 0, 0);
          
          that.feetImage = new Image();
          that.feetImage.src = canvas.toDataURL('image/jpeg', 1.0);
        }
        
        imgtemp.src = "data:image/jpeg;base64," + imageData.data;
        
        //this.feetImage = new Image();
        //this.feetImage.src = "data:image/jpeg;base64," + imageData.data;
      }
    },
    handleKeyPress(e) {
      
      //console.log(e);
      
      if (!e.repeat)
      {
        this.pressAccel = 1.0;
      }
      else
      {
        this.pressAccel += 1.0;
      }
        
      if (e.key == "Delete" && !e.repeat)
      {
       this.removePelotta();
      }
      else if (e.key == "s" && e.ctrlKey)
      {
        e.preventDefault();
        this.$emit("quickSave");
      }
      else if (e.key == "+")
      {
        this.resizePelotta(1);
      }
      else if (e.key == "-")
      {
        this.resizePelotta(-1);
      }
      else if (e.key == " ")
      {
        this.selectNextPelotta();
      }
      else if (e.key == "ArrowLeft")
      {
        this.rotatePelotta(-Math.min(Math.sqrt(this.pressAccel)/10, 10));
      }
      else if (e.key == "ArrowRight")
      {
        this.rotatePelotta(Math.min(Math.sqrt(this.pressAccel)/10, 10));
      }
      else if (e.key == "m"  && !e.repeat)
      {
        this.flipPelotta();
      }
      else if (e.key == "l"  && !e.repeat)
      {
        this.toggleLockPelotta();
      }
      else if ((e.key.toUpperCase() == 'A' || e.key.toUpperCase() == 'U' || e.key.toUpperCase() == 'X' || e.key.toUpperCase() == 'Y') && !e.ctrlKey)
      {
        this.changeBase(e.key);
      }
      else if (e.key == "z" && e.ctrlKey && (!e.shiftKey) && !e.repeat)
      {
        this.selectedShapeName = "";
        this.rotationMode = false;
        this.rotationInsideDraggingMode = false;
        this.draggingMode = false;
        this.pullFromHistory();
      }
      else if (e.key == "Z" && e.ctrlKey && e.shiftKey && !e.repeat)
      {
        this.redoFromHistory();
      }
    },
    selectNextPelotta() {
      if (this.selectedShapeName === "") {
        this.selectedShapeName = this.pelottaList[0].name;
      }
      else {
        var index = this.pelottaList.findIndex(({ name }) => name === this.selectedShapeName);
        index = (index + 1) % this.pelottaList.length;
        this.selectedShapeName = this.pelottaList[index].name;
      }
    },
    // clicked on stage or one of the pelottas - find closest pelotta (regardless of which actually received the click)
    getPelottaIndexUnderCursor(e) {
      var p;
      const pointerRelativeToStage = e.target.getStage().getRelativePointerPosition();
      const xPixelPerMM = this.width / this.widthMM;
      const yPixelPerMM = this.height / this.heightMM;
      
      var minDistancePelottaIndex = -1;
      var minDistance = Infinity;
      for (var i = 0; i < this.localDesign.pelottas.length; i++) {
        p = this.localDesign.pelottas[i];
        
        var cos = Math.cos(p.rotation * Math.PI/180);
        var sin = Math.sin(p.rotation * Math.PI/180);
        
        for (var j = 0; j < p.helperVertices.length; j++) {
          var vertexRelativeToStageX = p.x + xPixelPerMM * (p.helperVertices[j][0] * cos * p.scaleX - p.helperVertices[j][1] * sin);
          var vertexRelativeToStageY = p.y + yPixelPerMM * (p.helperVertices[j][0] * sin * p.scaleX + p.helperVertices[j][1] * cos);
          
          var distX = vertexRelativeToStageX - pointerRelativeToStage.x;
          var distY = vertexRelativeToStageY - pointerRelativeToStage.y;
          
          var distance = Math.sqrt(distX * distX + distY * distY);
          
          if (distance < minDistance && distance < this.maxSelectDistance) {
            minDistance = distance;
            minDistancePelottaIndex = i;
          }
        }
      }
      
      //console.log("minDistancePelottaIndex:", minDistancePelottaIndex, "minDistance:", minDistance);
      
      return minDistancePelottaIndex;
    },
    handleStageMouseDown(e) {
      if (this.readonly) return;
      
      if (this.lastReleaseTime && (Date.now()-this.lastReleaseTime < 300)) return; //somes we get spurious down events just after the doubleclick to put down a pelotta, this throws them away
      
      var pickedPelotta = this.getPickedPelotta();

      if (pickedPelotta)
      {
        var pos = e.target.getStage().getRelativePointerPosition();
        
        pickedPelotta.x = pos.x - pickedPelotta.widthMM / this.widthMM * this.width / 2 * (pickedPelotta.side == "left" ? -1 : 1);
        pickedPelotta.y = pos.y - pickedPelotta.heightMM / this.heightMM * this.height / 2;
        this.pushPelotta(pickedPelotta);
      }
      
      var startRotationInsideDragging = false;
      
      if (this.doubleClickDragMode && this.holdRightRotationMode && this.draggingMode && e.evt.button == 2)
      {
        startRotationInsideDragging = true;
      }
      else if (this.doubleClickDragMode && this.draggingMode && (this.selectedShapeName != "")) {
          return;
      }
      else if ((!this.holdRightRotationMode) && this.rotationMode && (this.selectedShapeName != "")) {
          return;
      }

      // TODO: check if the target was the stage or one of its descendants
      var minDistancePelottaIndex = this.getPelottaIndexUnderCursor(e);
      
      if (minDistancePelottaIndex < 0) {
        this.selectedShapeName = "";
        return;
      }
      
      if (this.selectedShapeName !== this.localDesign.pelottas[minDistancePelottaIndex].UID) {
        this.selectedShapeName = this.localDesign.pelottas[minDistancePelottaIndex].UID;
      }
      
      this.pushToHistory();
      
      if (e.evt.button == 0) {
        this.draggingMode = true;
        const pointerRelativeToStage = e.target.getStage().getRelativePointerPosition();
        this.draggingPelottaOffsetX = pointerRelativeToStage.x - this.localDesign.pelottas[minDistancePelottaIndex].x;
        this.draggingPelottaOffsetY = pointerRelativeToStage.y - this.localDesign.pelottas[minDistancePelottaIndex].y;
      } else if (e.evt.button == 2 && !startRotationInsideDragging) {
        this.rotationMode = true;
        console.log("rotation start");
      } else if (startRotationInsideDragging)
      {
        this.rotationInsideDraggingMode = true;
      }
    },
    handleStageMouseMove(e) {
      //these guard against the case when do not receive any "Up" event, but the button was released
      if ((e.evt.buttons & 2) == 0)
      {
        if (this.rotationInsideDraggingMode)
        {
          this.draggingMode = false;
          this.selectPelotta("");
        }
        
        this.rotationInsideDraggingMode = false;
        
        if (this.holdRightRotationMode)
        {
          this.rotationMode = false;
        }
        
        this.update();
      }
      
      if (this.doubleClickDragMode)
      {
        var pickedPelotta = this.getPickedPelotta();
        
        if (pickedPelotta)
        {
          const pointerRelativeToStage = e.target.getStage().getRelativePointerPosition();
          
          pickedPelotta.x = pointerRelativeToStage.x - pickedPelotta.widthMM / this.widthMM * this.width / 2 * (pickedPelotta.side == "left" ? -1 : 1);
          pickedPelotta.y = pointerRelativeToStage.y - pickedPelotta.heightMM / this.heightMM * this.height / 2;
          this.pushPelotta(pickedPelotta);
          
          this.draggingMode = true;
          this.draggingPelottaOffsetX = pointerRelativeToStage.x - pickedPelotta.x;
          this.draggingPelottaOffsetY = pointerRelativeToStage.y - pickedPelotta.y;
          
          this.selectPelotta(pickedPelotta.UID);
        }
      }
      
      if ((e.evt.buttons & 1) == 0 && !this.doubleClickDragMode)
      {
        this.draggingMode = false;
        this.update();
      }
      
      if (this.selectedShapeName != "") {
        const p = this.localDesign.pelottas[
            this.pelottaList.findIndex(
                ({ name }) => name === this.selectedShapeName
            )
        ];
        
        if (!p) {
            return;
        }
        
        if (this.rotationMode || this.rotationInsideDraggingMode) {
            if ((!this.holdRightRotationMode) || ((e.evt.buttons & 2) != 0)) {
                const moveY = e.evt.movementY / this.height * this.heightMM;
                this.rotatePelotta(moveY * 2.5);
            }
        } else if (this.draggingMode) {
            if ((this.doubleClickDragMode) || ((e.evt.buttons & 1) != 0)) {
                var pointerRelativeToStage = e.target.getStage().getRelativePointerPosition();
                p.x = pointerRelativeToStage.x - this.draggingPelottaOffsetX;
                p.y = pointerRelativeToStage.y - this.draggingPelottaOffsetY;
                this.update();
            }
        }
      }
    },
    handleStageMouseUp(e) {
      if ((e.evt.button == 0) && (!this.doubleClickDragMode) && this.draggingMode) {
          this.selectPelotta("");
          this.draggingMode = false;
      } else if ((e.evt.button == 2) && this.holdRightRotationMode && this.rotationMode) {
          this.selectPelotta("");
          this.rotationMode = false;
      } else if (e.evt.button == 2 && this.rotationInsideDraggingMode)
      {
        this.rotationInsideDraggingMode = false;
        const p = this.localDesign.pelottas[this.pelottaList.findIndex(({ name }) => name === this.selectedShapeName)];
        
        if (!p) {
            return;
        }
        
        const pointerRelativeToStage = e.target.getStage().getRelativePointerPosition();
        this.draggingPelottaOffsetX = pointerRelativeToStage.x - p.x;
        this.draggingPelottaOffsetY = pointerRelativeToStage.y - p.y;
      }
    },
    selectPelotta(PelottaName) {
      console.log(this.sortedPelottaList);
      this.selectedShapeName = PelottaName;
    },
    handleStageDblClick(e) {
      
      const p = this.localDesign.pelottas[
      this.pelottaList.findIndex(
        ({ name }) => name === this.selectedShapeName
      )
      ];
      if (p)
      {
        console.log(p.rootName, p.x, p.y);
      }
      
      const pointerRelativeToStage = e.target.getStage().getRelativePointerPosition();
      console.log(pointerRelativeToStage.x, pointerRelativeToStage.y);
      
      
      
      if ((e.evt.button == 0) && this.doubleClickDragMode && this.draggingMode) {
          console.log("Released", this.selectedShapeName);
          if (this.selectedShapeName != "")
          {
            this.lastReleaseTime = Date.now();
          }
          this.selectedShapeName = "";
          this.draggingMode = false;
      } else if ((e.evt.button == 2) && (!this.holdRightRotationMode) && this.rotationMode) {
          this.selectedShapeName = "";
          this.rotationMode = false;
      }
    },
    fitStageIntoParentContainer() {
      const container = document.querySelector(`#konva-parent-${this._uid}`);
      if (!container) return;
      const stage = this.$refs.stage.getStage();
      
      var scale = 1.0;
      
      if (this.preview)
      {
        const containerWidth = container.offsetWidth;
        scale = containerWidth / this.width;
      }
      else
      {
        scale = (window.innerHeight - 55) / (this.height);
      }
      
      //console.log("this.height * scale:", this.height * scale);
      
      stage.width(this.width * scale);
      stage.height(this.height * scale);
      stage.scale({ x: scale, y: scale });
    },
    rotatePelotta(angle) {
      if (this.selectedShapeName)
      {
        const p = this.localDesign.pelottas[this.currentPelottaIndex];
        
        if (p)
        {
          var nodeWidthHalf = p.widthMM / this.widthMM * this.width / 2;
          var nodeHeightHalf = p.heightMM / this.heightMM * this.height / 2;
          
          var shift = 0;
          
          var rx1 = nodeWidthHalf*Math.cos((p.rotation+shift)/180*Math.PI)*p.scaleX - nodeHeightHalf*Math.sin((p.rotation+shift)/180*Math.PI);
          var ry1 = nodeWidthHalf*Math.sin((p.rotation+shift)/180*Math.PI)*p.scaleX + nodeHeightHalf*Math.cos((p.rotation+shift)/180*Math.PI);

          var rx2 = nodeWidthHalf*Math.cos(((p.rotation+angle+shift))/180*Math.PI)*p.scaleX - nodeHeightHalf*Math.sin(((p.rotation+angle+shift))/180*Math.PI);
          var ry2 = nodeWidthHalf*Math.sin(((p.rotation+angle+shift))/180*Math.PI)*p.scaleX + nodeHeightHalf*Math.cos(((p.rotation+angle+shift))/180*Math.PI);
          
          //console.log(rx1-rx2, ry1-ry2, angle);
          
          p.x += rx1-rx2;
          p.y += ry1-ry2;
          
          p.rotation += angle;
          
          this.update();
        }
      }
    },
    changePelotta(p, changeToPelotta)
    {
      if (p.PelottaID == changeToPelotta.PelottaID)
      {
        return;
      }
      
      this.pushToHistory();
      
      var nodeWidthHalfOrig = p.widthMM / this.widthMM * this.width / 2;
      var nodeHeightHalfOrig = p.heightMM / this.heightMM * this.height / 2;
      
      var nodeWidthHalf = changeToPelotta.widthMM / this.widthMM * this.width / 2;
      var nodeHeightHalf = changeToPelotta.heightMM / this.heightMM * this.height / 2;
      
      var rx1 = nodeWidthHalfOrig*Math.cos((p.rotation)/180*Math.PI)*p.scaleX - nodeHeightHalfOrig*Math.sin((p.rotation)/180*Math.PI);
      var ry1 = nodeWidthHalfOrig*Math.sin((p.rotation)/180*Math.PI)*p.scaleX + nodeHeightHalfOrig*Math.cos((p.rotation)/180*Math.PI);
      
      var rx2 = nodeWidthHalf*Math.cos((p.rotation)/180*Math.PI)*p.scaleX - nodeHeightHalf*Math.sin((p.rotation)/180*Math.PI);
      var ry2 = nodeWidthHalf*Math.sin((p.rotation)/180*Math.PI)*p.scaleX + nodeHeightHalf*Math.cos((p.rotation)/180*Math.PI);
      
      //console.log(p.widthMM, p.heightMM, svgWidthMM, svgHeightMM);
      
      p.x += rx1 - rx2;
      p.y += ry1 - ry2;
      
      p.PelottaID = changeToPelotta.PelottaID;
      p.sizeCode = changeToPelotta.size;
      p.helperVertices = changeToPelotta.helperVertices;
      this.update();
    },
    resizePelotta(step = 0) {
      if (this.readonly) return;

      const p = this.localDesign.pelottas[this.currentPelottaIndex];

      if (p)
      {
        const changeToPelotta = this.currentGroup.Items[
          Math.max(
            0,
            Math.min(
              this.currentPelottaIndexInGroup + step,
              this.currentGroup.Items.length - 1
            )
          )
        ];

        if (p.PelottaID !== changeToPelotta.PelottaID) {
          this.changePelotta(p, changeToPelotta);
        }
      }
    },
    toggleLockPelotta() {
      if (this.readonly) return;
      
      const p = this.localDesign.pelottas[
        this.pelottaList.findIndex(
          ({ name }) => name === this.selectedShapeName
        )
      ];
      
      console.log("toggle lock", p, this.selectedShapeName);
      
      if (p)
      {
        this.pushToHistory();
        p.locked = !p.locked;
      
        if (p.locked)
        {
          this.selectedShapeName = "";
        }
      }
      
      this.update();
    },
    flipPelotta() {
      if (this.readonly) return;

      const p = this.localDesign.pelottas[
        this.pelottaList.findIndex(
          ({ name }) => name === this.selectedShapeName
        )
      ];
      
      var nodeWidthHalfOrig = p.widthMM / this.widthMM * this.width / 2;
      var nodeHeightHalfOrig = p.heightMM / this.heightMM * this.height / 2;
      
      var rx1 = nodeWidthHalfOrig*Math.cos((p.rotation)/180*Math.PI)*p.scaleX - nodeHeightHalfOrig*Math.sin((p.rotation)/180*Math.PI);
      var ry1 = nodeWidthHalfOrig*Math.sin((p.rotation)/180*Math.PI)*p.scaleX + nodeHeightHalfOrig*Math.cos((p.rotation)/180*Math.PI);
      
      p.x += rx1;
      p.y += ry1;
      
      p.scaleX *= -1.0;
      
      rx1 = nodeWidthHalfOrig*Math.cos((p.rotation)/180*Math.PI)*p.scaleX - nodeHeightHalfOrig*Math.sin((p.rotation)/180*Math.PI);
      ry1 = nodeWidthHalfOrig*Math.sin((p.rotation)/180*Math.PI)*p.scaleX + nodeHeightHalfOrig*Math.cos((p.rotation)/180*Math.PI);
      
      p.x -= rx1;
      p.y -= ry1;
      
      if (p && !p.locked)
      {
        this.pushToHistory();
        p.mirrored = ! p.mirrored;
      }
    
      this.update();
    },
    removePelotta() {
      if (this.readonly) return;
      const idx = this.pelottaList.findIndex(
        ({ name }) => name === this.selectedShapeName
      );
      this.selectedShapeName = "";
      
      if (this.localDesign.pelottas[idx] && !this.localDesign.pelottas[idx].locked) {
        this.pushToHistory();
        this.localDesign.pelottas.splice(idx, 1);
      }
      
      this.update();
    },
    changeBase(targetName) {
      if (this.readonly) return;
      
      const p = this.localDesign.pelottas[
        this.pelottaList.findIndex(
          ({ name }) => name === this.selectedShapeName
        )
      ];
      
      //console.log("p: ", p);
      
      if (p) {
        var res = this.pelottas.filter(({ name, size }) => name == targetName.toUpperCase() && size == p.sizeCode);
        
        if (res.length > 0 && p.PelottaID === res[0].PelottaID)
        {
          return;
        }
        
        if (res.length > 0)
        {
          this.changePelotta(p, res[0]);
        }
        
        //console.log(res);
        //console.log(this.pelottas.filter(({ Name }) => Name == targetName.toUpperCase()));
      }
    },
    
    getSortedPelottaListSide(querySide) {
      return this.sortedPelottaList.filter(({side}) => side == querySide);
    }
  },
  watch: {
    colorScheme() {
      this.downloadFeetImage();
    }
  }
};
</script>

<style>
.v-list-item {
  flex: 0;
}
</style>
